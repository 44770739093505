import React from 'react';
import Caution from '../components/Caution';

function Notes() {
  return (
    <div>
      <Caution />
    </div>
  );
}

export default Notes;