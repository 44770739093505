import React from 'react';
import ACCESS from '../components/ACCESS';

function Access() {
  return (
    <div>
<ACCESS />
    </div>
  );
}

export default Access;