import React from 'react';
import TopicsPages from '../components/TopicsPages.jsx';

function TopicDetail() {
    return (
    <div>
        <TopicsPages />
    </div>
    );
}

export default TopicDetail;